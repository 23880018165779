html {
  font-size: 16px;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  html {
    font-size: 12px;
  }
}

body {
  margin: 0;
  /* Variables */
  --main-accent-color: #04fbc0;
}

h1 {
}

h1-mobile {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.logo-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  margin: 20px 0 0 20px;
}

.logo-img {
  max-width: 200px;
}

.contact-title {
  margin-top: 15vh;
}

.walk-test {
  display: flex;
  justify-content: center;
  text-align: center;
}

/* Fullpage.js Transition Animations*/

/**
 * ----------------------------------------
 * animation scale-in-hor-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}
@keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swing elements on card
 * ----------------------------------------
 */

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  90% {
    transform: rotate(2deg);
  }
  90% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/*Bouncing Icon Styles*/

@-moz-keyframes bounce {
  0%,
  8%,
  20%,
  32%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  16% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  24% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%,
  8%,
  20%,
  32%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  16% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  24% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  8%,
  20%,
  32%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  16% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  24% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
/* divided all of these percentace values by 5 and then multiplied by 2 - and changed bounce duration to 5s */

.info {
  position: relative;
  opacity: 1;
  transition: all 0.2s;
}

.info :hover {
  opacity: 0.8;
}

.bounce {
  -moz-animation: bounce 5s infinite;
  -webkit-animation: bounce 5s infinite;
  animation: bounce 5s infinite;
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #000;
  stroke-width: 5.5;
  stroke-linecap: round;
}
.ham1 .top {
  stroke-dasharray: 40 139;
}
.ham1 .bottom {
  stroke-dasharray: 40 180;
}
.ham1.active .top {
  stroke-dashoffset: -98px;
}
.ham1.active .bottom {
  stroke-dashoffset: -138px;
}

/* Mobile Menu Animation & Indicator Styles */

.fp-viewing-developer .developer-mi .MuiButton-label {
  color: var(--main-accent-color);
}

.fp-viewing-services .services-mi .MuiButton-label {
  color: var(--main-accent-color);
}

.fp-viewing-portfolio .portfolio-mi .MuiButton-label {
  color: var(--main-accent-color);
}

.fp-viewing-experiments .experiments-mi .MuiButton-label {
  color: var(--main-accent-color);
}

.fp-viewing-contact .contact-mi .MuiButton-label {
  color: var(--main-accent-color);
}
/* --- */

/* Mobile Menu Link & Indicator Styles */
/* .menu-link__effect {
  position: relative;
}
.menu-link__effect:after {
  position: absolute;
  content: "";
  display: block;
  right: 0;
  width: 0;
  bottom: -4px;
  height: 2px;
  background: var(--main-accent-color);
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-delay: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-delay: 0;
}
.menu-link__effect:hover:after {
  width: 100%;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-delay: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-delay: 0;
} */

/* Layout Media Queries */

@media only screen and (max-width: 767px) {
  #fp-nav {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .site-logo {
    padding: 0;
  }
}
