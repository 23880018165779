#fp-nav.fp-right {
  /* can be used to move around the side nav. */
}

#fp-nav ul li,
.fp-slidesNav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 0px 8px 36px 8px;
  position: relative;
}

/* Sizing and position of the clickable are on SideNav */
#fp-nav ul li a,
.fp-slidesNav ul li a {
  display: block;
  padding-left: 180px;
  margin-left: -150px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 150%;
  cursor: pointer;
  text-decoration: none;
}

#fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -2px;
  color: #232323;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  display: block;
  opacity: 1;
  width: auto;
  cursor: pointer;
  transition: all 0.5s ease-in;
}

/* Changes SideNav and TopNav Styles when viewing the first fp*/
/* .fp-viewing-0 #fp-nav ul li .fp-tooltip,
.fp-viewing-1 #fp-nav ul li .fp-tooltip {
  color: white;
}

.fp-viewing-0 .MuiTab-textColorInherit,
.fp-viewing-1 .MuiTab-textColorInherit {
  color: white;
}
.fp-viewing-0 .MuiTab-textColorInherit:hover {
  color: #d3d3d3;
}

.fp-viewing-0 .PrivateTabIndicator-colorSecondary-66,
.fp-viewing-1 .PrivateTabIndicator-colorSecondary-66 {
  background-color: #d3d3d3 !important;
}

*/

/* .PrivateTabIndicator-colorSecondary-66 {
  background-color: #04fbc0;
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: transparent !important;
} */

/* Indicator Styles*/

#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
  height: 16px;
  opacity: 1;
  width: 4px;
  margin: -6px 0 0 60px;
  border-radius: 0;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 0;
  position: absolute;
  z-index: 1;
  height: 10px;
  width: 4px;
  border: 0;
  background: #04fbc0;
  left: 50%;
  opacity: 0;
  top: 25%;
  margin: -2px 0 0 60px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
